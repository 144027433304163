@media (prefers-color-scheme: light) {
  :root {
    /* White colors - Used for testing */

    --bkg-primary: #f3f3fa;
    --bkg-tabs: rgb(186 193 210);
    --bkg: #aaabbb;
    --bkg-button-primary: #dc334f;
    --bkg-button-primary-disabled: rgba(152, 152, 155, 0.35);
    --bkg-button-icon: unset;
    --bkg-button-tertiary: rgba(99, 118, 151, 0.3);
    --bkg-header: #2e3254;
    --bkg-header-manager: #130c17;
    --bkg-left-menu: #202234;
    --bkg-list-boxes: #cfcfd3;
    --bkg-section: rgba(99, 118, 151, 0.15);
    --bkg-section-secondary: rgba(54, 147, 209, 0.5);
    --bkg-table-active-row: rgba(99, 118, 151, 0.3);
    --bkg-table-disabled-row: rgba(99, 118, 151, 0.15);
    --bkg-field: rgba(244, 244, 249, 0.6);
    --bkg-frosted-modal-field: rgb(229 231 241);
    --bkg-frosted-modal: rgb(242 242 251 / 92.5%);
    --bkg-table-sticky: rgb(222 225 236);

    --backdrop: rgba(204, 204, 204, 0.19);

    --txt-secondary: #3f3f3f;
    --txt-primary: #1e1f20;
    --txt-light: #fafafa;
    --txt-button-primary: #efefef;
    --txt-header: #4eabe5;

    --border-primary: unset;
    --border-cards-lines: 1px solid #8a8a8c;
    --border-frosted-modal: 0.5px solid #f4f4f9;
    --border-button-icon: 1px solid #1f2228;
    --border-button-icon-disabled: 1px solid #98989b;

    --border-radius-button-icon: 0.25rem;
    --border-radius-button-tertiary: 0.25rem;

    --color-button-icon: #2e2e2a;
    --color-button-tertiary: #f4f4f9;
    --color-button-tertiary-filter: invert(18%) sepia(42%) saturate(583%)
      hue-rotate(195deg) brightness(91%) contrast(88%);
    --color-icons: #f4f4f9;
    --color-primary: #f2f3f6;

    --box-shadow--button-icon: unset;

    --dashboard-widget-card: #e9e9ed;
    --dashboard-background: #dbdce2;

    /*Old Variable Names - Need to change*/
    --text-btn: #fff;
    --text-darkblue: #333e52;
    --bg-darkblue: #697b9b;
    --bg-lightblue: #9cabc7;
    --bg-lightblue-secondary: #acc3e85c;
    --bg-verylightblue: #acc3e8;
    --bg-darkpurple: #77748f;
    --bg-lightpurple: #c7c5dc;
    --bg-white: #fafafa;
    --bg-request-brown: #a79a97;
    --bg-lightbluebutton: #9cabc7;
    --button-darkblue: #2c4b81;
    --transition-speed: 200ms;
    --tech-bkg: #181a1e;
    --red-tag: #e51e25;
    --blue-tag: #4eabe5;
    --grey-tag: #3b3c3a;

    /* TAG-Portal */
    --tag-prim-btn: #b43f5b;
    --tag-selected: #299be8;
    --tag-mid-tone: #8d95ac;
    --tag-dark: #313552;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark colors - Used for testing */
    --bkg-primary: #232328;
    --bkg-tabs: rgb(61 68 84);
    --bkg: #121212;
    --bkg-button-primary: #dc334f;
    --bkg-button-primary-disabled: rgba(152, 152, 155, 0.35);
    --bkg-button-icon: unset;
    --bkg-button-tertiary: rgba(99, 118, 151, 0.3);
    --bkg-header: #121212;
    --bkg-header-manager: #121212;
    --bkg-left-menu: #1a1a1a;
    --bkg-list-boxes: #444e62;
    --bkg-section: rgba(99, 118, 151, 0.15);
    --bkg-section-secondary: rgba(54, 147, 209, 0.5);
    --bkg-table-active-row: rgba(99, 118, 151, 0.3);
    --bkg-table-disabled-row: rgba(99, 118, 151, 0.15);
    --bkg-field: #b3b3b31a;
    --bkg-frosted-modal-field: #b3b3b31a;
    --bkg-frosted-modal: #232328e0;
    --bkg-table-sticky: rgb(45 48 57);

    --backdrop: rgba(0, 0, 0, 0.32);

    --txt-secondary: #d9d9de;
    --txt-primary: #f4f4f9;
    --txt-light: #fafafa;
    --txt-button-primary: #efefef;
    --txt-header: #4eabe5;

    --border-primary: unset;
    --border-cards-lines: 1px solid #8a8a8c;
    --border-frosted-modal: 0.5px solid #1a1a1e;
    --border-button-icon: 1px solid #a9a9a9;
    --border-button-icon-disabled: 1px solid #98989b;

    --border-radius-button-icon: 0.25rem;
    --border-radius-button-tertiary: 0.25rem;

    --color-button-icon: #a9a9a9;
    --color-button-tertiary: #f4f4f9;
    --color-button-tertiary-filter: invert(100%) sepia(9%) saturate(1580%)
      hue-rotate(180deg) brightness(100%) contrast(95%);
    --color-icons: #f4f4f9;
    --color-primary: #f2f3f6;

    --box-shadow--button-icon: unset;

    --dashboard-widget-card: #b3b3b31a;

    /*Old Variable Names - Need to change*/
    --text-btn: #fff;
    --text-darkblue: #333e52;
    --bg-darkblue: #697b9b;
    --bg-lightblue: #9cabc7;
    --bg-lightblue-secondary: #acc3e85c;
    --bg-verylightblue: #acc3e8;
    --bg-darkpurple: #77748f;
    --bg-lightpurple: #c7c5dc;
    --bg-white: #fafafa;
    --bg-request-brown: #a79a97;
    --bg-lightbluebutton: #9cabc7;
    --button-darkblue: #2c4b81;
    --transition-speed: 200ms;
    --tech-bkg: #181a1e;
    --red-tag: #e51e25;
    --blue-tag: #4eabe5;
    --grey-tag: #3b3c3a;

    /* TAG-Portal */
    --tag-prim-btn: #b43f5b;
    --tag-selected: #299be8;
    --tag-mid-tone: #8d95ac;
    --tag-dark: #313552;
  }
}

::-webkit-scrollbar-track {
  background-color: transparentize(#ccc, 0.7);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: transparentize(#697fa7, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

html {
  scroll-behavior: smooth;
}

:root {
  overflow: hidden;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

body {
  color: var(--txt-primary);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.gap-3 {
  gap: 1rem;
}

.background {
  height: 100%;
  width: 100%;
  background: var(--bkg);
  background-repeat: no-repeat;
  background-size: cover;
}

.dx-placeholder {
  display: none;
}

i {
  user-select: none;
}

*:focus {
  outline: none !important;
}

.cards-container {
  height: calc(100% - 60px) !important;
}

.fullscreen-dialog {
  max-width: calc(100vw - 2rem) !important;
  height: calc(100vh - 60px - 2rem) !important;
  margin-top: 60px !important;
}

.flex-1 {
  flex: 1;
}

.borderTitle {
  border-top: var(--border-cards-lines);
  float: left;
  margin: -1.25rem 0px !important;
  width: 100%;
  height: 0px;
  overflow: visible;
}

.dynamic-look-up {
  width: 45%;
  height: 55%;
}

.dynamic-high-look-up {
  height: 85%;
  width: 50%;
}

.Diapo {
  position: absolute !important;
  top: 15vh;
  left: 25vw;
  background-color: transparent;
  width: 50vw;
  height: auto;
  min-height: 50vh;
  border: 1px solid transparent;
}

.Diapo .mat-mdc-dialog-container {
  overflow: hidden !important;
  padding: 0px !important;
  box-shadow: unset !important;
  background-color: transparent;
}

.confirm-dialog {
  height: auto;
}

.confirm-dialog .mat-mdc-dialog-container {
  overflow: hidden;
  padding: 0px !important;
}

.attach-object {
  position: absolute !important;
  top: 5rem;
  width: 45vw;
  height: 65vh;
  left: 25vw;
}

.attach-object .mat-mdc-dialog-container {
  overflow: hidden;
}

.help-modal .mat-mdc-dialog-container {
  padding: 0px !important;
  overflow: hidden;
}

.refresh-icon {
  box-shadow: var(--box-shadow-button-icon);
  background-color: var(--bkg-button-tertiary);
  border-radius: var(--border-radius-button-tertiary);
  color: var(--color-button-tertiary);
}

/*---------- Form submit validation -------------*/
span.data-danger {
  position: relative;
  float: right;
  color: red !important;
}

ul.tracking li .ExtraData {
  font-size: 0.8rem;
}

.hortizontal-form {
  padding: 0.5rem;
}

.hortizontal-form label {
  margin: auto 0;
  font-size: 0.8125rem;
  color: var(--txt-secondary) !important;
}

.hortizontal-form .form-group {
  border-bottom: var(--border-cards-lines);
}

.hortizontal-form .stretch {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hortizontal-form .stretch > div {
  padding: unset;
}

.hortizontal-form span {
  color: var(--txt-secondary) !important;
  font-size: 0.8125rem;
  font-weight: 300;
  border: unset;
  background-color: transparent;
  width: calc(100% + 1rem);
  padding-right: 2px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-group {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-group label {
  margin-bottom: 0.15rem;
}

.panel-notification {
  position: absolute !important;
  top: 60px;
  width: 25vw;
  z-index: 1200;
  height: calc(100vh - 60px);
  box-shadow: #00000024 -2px 7px 14px 0px;
  right: 0;
}

.panel-notification .mat-mdc-dialog-container {
  padding-top: 0px !important;
  overflow: auto !important;
  background: var(--bkg-primary);
  border-color: var(--border-primary);
  border-radius: 0px !important;
  max-height: 100% !important;
  color: var(--txt-primary);
  border: unset;
}

.card {
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.mat-horizontal-content-container {
  height: 100% !important;
}

.mat-horizontal-stepper-content[aria-expanded='true'] {
  height: 95%;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
}

.filter-drag {
  z-index: 1100;
  height: 200px;
}

.big-container {
  position: relative;
  height: 100%;
  display: flex;
  gap: 1rem;
}

.schedule--selected {
  background-color: var(--bg-darkblue) !important;
}

.btnSubmitReview {
  width: 100%;
  background-color: var(--bkg-button-primary);
  color: var(--text-btn);
}

.small-title {
  color: var(--txt-primary);
  font-size: 1.1rem;
}

/* Tag priority colors */

.routine {
  border-left: 5px solid rgba(47, 121, 167, 0.8) !important;
}

.routine > div {
  background-color: rgba(47, 121, 167, 0.8) !important;
  border: 1px solid #4eabe5;
}

.routine-label-from {
  color: rgba(47, 121, 167, 0.8) !important;
}

.critical {
  border-left: 5px solid rgba(168, 82, 114, 0.8) !important;
}

.critical > div {
  background-color: rgba(168, 82, 114, 0.8) !important;
  border: 1px solid #f95e97;
}

.critical-label-from {
  color: rgba(168, 82, 114, 0.8) !important;
}

.emergency {
  border-left: 5px solid rgba(168, 82, 114, 0.8) !important;
}

.emergency > div {
  background-color: rgba(168, 82, 114, 0.8) !important;
  border: 1px solid #f95e97 !important;
}

.emergency-label-from {
  color: rgba(168, 82, 114, 0.8) !important;
}

.deferred {
  border-left: 5px solid rgba(135, 135, 143, 0.8) !important;
}

.deferred > div {
  background-color: rgba(135, 135, 143, 0.8) !important;
  border: 1px solid #e9e9f6 !important;
}

.deferred-label-from {
  color: rgba(135, 135, 143, 0.8) !important;
}

.special-attention {
  border-left: 5px solid rgb(243, 188, 6) !important;
}

.special-attention > div {
  background-color: rgb(243, 188, 6) !important;
}

.special-attention-label-from {
  color: rgb(243, 188, 6) !important;
  font-size: 1rem !important;
}

.urgent {
  border-left: 5px solid rgba(168, 82, 114, 0.8) !important;
}

.urgent > div {
  background-color: rgba(168, 82, 114, 0.8) !important;
  border: 1px solid #f95e97;
}

.urgent-label-from {
  color: rgba(168, 82, 114, 0.8) !important;
}

.selected-label-from {
  color: #056a8f !important;
}

/* DX DataGrid */

dx-data-grid {
  height: 100%;
  width: 100%;
}

dx-data-grid .dx-datagrid {
  background-color: transparent !important;
}

dx-data-grid .dx-data-row > td {
  font-size: 0.875rem !important;
}

dx-data-grid .dx-header-row > td,
dx-data-grid .dx-pager,
dx-data-grid .dx-texteditor-input {
  font-size: 0.75rem !important;
}

dx-data-grid .dx-datagrid-header-panel,
dx-data-grid .dx-toolbar {
  background-color: transparent !important;
  padding: 0;
}

dx-data-grid .dx-toolbar-label > div {
  padding: 0 5px 0 calc(1rem + 5px) !important;
}

dx-data-grid .dx-selection,
dx-data-grid .dx-state-hover {
  cursor: pointer;
}

dx-data-grid .dx-datagrid-toolbar-button.dx-button,
dx-data-grid .dx-button.dx-button-normal {
  box-shadow: var(--box-shadow-button-icon) !important;
  background-color: var(--bkg-button-tertiary);
  border-radius: var(--border-radius-button-tertiary) !important;
}

dx-data-grid .dx-button-content > .dx-icon {
  color: var(--color-button-tertiary) !important;
}

dx-data-grid .dx-datagrid-filter-row {
  background-color: transparent !important;
}

dx-data-grid .dx-col-fixed {
  background-color: var(--bkg-primary) !important;
}

/* Toastr overwrites */

.toast-top-right {
  top: 70px !important;
}

.toast-container .ngx-toastr {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

/* Expansion Panel */
mat-expansion-panel mat-expansion-panel-header {
  background-color: var(--bkg-tabs) !important;
  color: var(--txt-primary) !important;
  border-radius: 0px !important;
  padding: 10px 1rem !important;
  height: 39px !important;
}

mat-expansion-panel mat-panel-title {
  color: var(--txt-primary) !important;
}

mat-expansion-panel mat-expansion-panel-header .mat-content {
  height: 19px !important;
  overflow: visible;
}

mat-expansion-panel mat-expansion-panel-header .mat-content > mat-chip {
  margin-top: -2px;
}

mat-expansion-panel {
  border-radius: 0px !important;
  background: none !important;
  box-shadow: none !important;
}

mat-expansion-panel .mat-expansion-panel-body {
  padding: 4px 4px 0px !important;
}

/* DX Scheduler style overwrite */

dx-scheduler .dx-scheduler-scrollable-fixed-content {
  height: 100% !important;
}

dx-scheduler .dx-scheduler-container,
dx-scheduler .dx-scheduler-all-day-panel,
dx-scheduler .dx-scheduler-work-space,
dx-scheduler .dx-scheduler-header {
  border: unset;
  background-color: transparent;
}

/* DX-Tree */

dx-tree-list {
  height: 100%;
}

dx-tree-list .dx-treelist-container,
dx-tree-list .dx-treelist-header-panel,
dx-tree-list .dx-toolbar {
  background-color: transparent !important;
}

dx-tree-list .dx-row.dx-data-row.dx-row-lines {
  cursor: pointer;
}

dx-tree-list .dx-row.dx-data-row.dx-row-lines:hover {
  background-color: #0000000d;
}

dx-tree-list .dx-treelist-toolbar-button.dx-button,
dx-tree-list .dx-button.dx-button-normal {
  box-shadow: var(--box-shadow-button-icon) !important;
  background-color: var(--bkg-button-tertiary);
  border-radius: var(--border-radius-button-tertiary) !important;
}

dx-tree-list .dx-button-content > .dx-icon {
  color: var(--color-button-tertiary) !important;
}

/* DX-License */
dx-license {
  display: 'none' !important;
}

/* DX-Images */

dx-gallery .dx-gallery-item-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Dx Drop Down Buttons */

.dx-dropdownbutton {
  border-radius: var(--border-radius-button-icon);
  height: 36px;
}

.dx-dropdownbutton .dx-button-content {
  padding-left: 6px !important;
}

dx-drop-down-button .dx-buttongroup-item:not(.dx-state-disabled) {
  background-color: var(--bkg-button-primary) !important;
  color: var(--text-btn);
}

dx-drop-down-button .dx-button-text {
  text-transform: none;
}
dx-drop-down-button .dx-icon {
  color: var(--text-btn) !important;
}

/* Mat dialog container */
.cdk-overlay-container {
  z-index: 10000;
}

mat-dialog-container.mat-mdc-dialog-container
  .mat-mdc-dialog-surface.mdc-dialog__surface {
  background-color: var(--bkg-frosted-modal);
}

/* Perfect Scrollbar */

perfect-scrollbar .ps__rail-x,
perfect-scrollbar .ps__rail-y {
  z-index: 1;
}

/* Mat Toggle */

/* Mat Table */
mat-table .mat-sort-header-container {
  font-size: small;
}

mat-table .mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0px 8px !important;
}

/* CDK Drag Animations */

.cdk-drag-preview {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-preview.example-box {
  padding: 1rem;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
  border-radius: 5px;
  background: var(--bkg-section-secondary);
  font-size: 14px;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Dx Drag Style */

.dx-draggable-source {
  opacity: 0.5;
  white-space: nowrap;
}

.dx-draggable-dragging > * {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

/* Mat-Tab */

.mat-tab__full-card .mat-mdc-tab-body-wrapper {
  height: 100%;
}

.mat-tab__full-card {
  height: 100%;
}

mat-tab-group .mat-mdc-tab-header {
  overflow: hidden;
  border-radius: var(--border-radius-button-tertiary);
}

mat-tab-group .mat-mdc-tab-label-container {
  background-color: var(--bkg-tabs);
  border-radius: var(--border-radius-button-tertiary);
  height: 2.5rem;

  .mdc-tab {
    flex: 1;
    color: var(--txt-primary);
    height: 2.5rem;
    min-width: 100px;
  }
}

mat-tab-group .mdc-tab {
  font-size: 0.875rem;
}

mat-tab-group .mat-mdc-tab-header {
  border-bottom: unset;
  padding: 1rem 1rem 0 1rem;
}

mat-tab-group .mat-mdc-tab-header-pagination {
  box-shadow: unset;
}

.mdc-tab.mat-mdc-tab-disabled,
.mat-mdc-tab-link.mat-mdc-tab-disabled {
  opacity: 0.15;
  color: unset;
}

/* Mat Form Field */
.mat-mdc-form-field
  .mat-mdc-form-field-flex
  .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: translateY(-0.5rem);
}

.mat-mdc-form-field-icon-suffix {
  display: flex;
}

.mat-mdc-form-field-icon-prefix {
  padding-left: 4px !important;
}

.mat-mdc-form-field-icon-suffix mat-icon {
  color: #646464;
}

.mat-form-field-disabled .mat-mdc-form-field-icon-suffix > p {
  color: #646464;
}

.mat-form-field-disabled .mat-form-field-prefix {
  color: #646464;
}

.mat-form-field-appearance-fill:not(.mat-form-field-disabled)
  .mat-mdc-text-field-wrapper {
  background-color: var(--bkg-field);
}

.mat-form-field-appearance-fill.mat-form-field-disabled > div:first-of-type {
  background-color: var(--bkg-field);
}

.mat-form-field-appearance-fill.mat-form-field-disabled {
  opacity: 0.75;
}

.modal-overlay
  .mat-form-field-appearance-fill:not(.mat-form-field-disabled)
  .mat-mdc-text-field-wrapper,
.mat-mdc-dialog-container
  .mat-form-field-appearance-fill:not(.mat-form-field-disabled)
  .mat-mdc-text-field-wrapper {
  background-color: var(--bkg-frosted-modal-field);
}

/* Mat Button Styling */
.mat-mdc-mini-fab.mat-mdc-button-base {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  box-shadow: none;
  padding: 0 !important;
}

.mat-mdc-mini-fab.mat-button-disabled {
  background-color: unset !important;
  border: var(--border-button-icon-disabled) !important;
}

.mat-mdc-outlined-button:not(:disabled) {
  border: var(--border-button-icon) !important;
  border-radius: var(--border-radius-button-icon) !important;
  color: var(--color-button-icon) !important;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 2.5rem;
  font-size: 0.85rem !important;
}

/* Mat drop down */

/* Mat Chip */
mat-chip-listbox {
  display: flex;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  overflow: hidden;
  line-height: 200%;
  font-size: 0.75rem !important;
  cursor: pointer !important;
  display: flex;

  &:not(.mdc-evolution-chip--disabled) {
    background-color: var(--bkg-button-tertiary);
    color: var(--color-primary);
  }

  .mat-mdc-chip-action-label {
    gap: 0.5rem;
    display: flex;
  }
}

.mat-mdc-chip.mdc-evolution-chip {
  min-width: 25px;
  text-align: center;
  margin-right: 10px;
  border-radius: 1.25rem;
}

/* Mat button toggle */

/* Mat icon and DX Icon */
.mat-icon {
  height: unset !important;
}

/* Mat Table */

/* mat-checkbox */
mat-checkbox.mat-mdc-checkbox {
  display: flex;

  .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: whitesmoke;
  }
}

mat-checkbox .mdc-label {
  margin-bottom: unset;
}

/* Mat Stepper */

/* mat-drawer-content */

.mat-drawer-container {
  background-color: var(--bkg-primary);
}

.bkg-color .mat-drawer-container,
.bkg-color .mat-drawer-content {
  background-color: var(--bkg) !important;
}
